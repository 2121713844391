<template>
  <div id="app">
    <AppHeader />
    <main>
      <AppLayout />
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppLayout from './components/AppLayout.vue';
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppLayout,
    AppHeader,
    AppFooter,
  }
};
</script>
