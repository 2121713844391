<template>
    <footer class="c-footer c-text-light" role="contentinfo">
        <span class="c-footer-deco"></span>
        <a href="#" @click.prevent="scrollToTop" class="c-icon c-link-top"><span class="c-hide-visually">Go to top</span></a>
       
        <!-- footer main-->
        <div class="c-container c-container-no-padding c-footer-main">
            <div class="c-row">
                <div class="c-col-12">
                    <div class="c-header-logo c-footer-logo">
                        <div class="c-header-logo-1">
                            <router-link to="/home">
                                <img src="../assets/images/logo-dispomanager-white.png" alt="" />
                            </router-link>
                        </div>
                        <div class="c-header-logo-2">
                            <router-link to="/home">
                                <img src="../assets/images/logo-regiomed-white.svg" alt="" />
                            </router-link>
                        </div>
                    </div>
                    <h2 class="c-h4">Die innovative Bestellplattform von Regiomed.</h2>
                </div>
            </div>
            <div class="c-row">
                <div class="c-col-4">
                    <h3 class="c-h5">Kontakt</h3>
                    <p>Kontaktadresse Kundenservice Dispomanager</p>
                    
                    <a href="mailto:info@dispomanager.ch">info@dispomanager.ch</a><br>
                    <a href="tel:+41791234567">+41 79 123 45 67</a>
                </div>
                <div class="c-col-4">
                    <h3 class="c-h5">Direktlinks</h3>
                    <ul class="c-footer-nav-list">
                        <li><a href="">Bestellen mit Scanner</a></li>
                        <li><a href="">Bestellen via Upload</a></li>
                        <li><a href="">Reports generieren</a></li>
                        <li><router-link to="/shop/products">Produktsuche</router-link></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="c-container c-container-no-padding c-footer-disclaimer">
            <div class="c-row c-row-reverse">
                <div class="c-col-8 c-text-right">
                    <ul class="c-footer-disclaimer-list">
                        <li><a href="/">Impressum</a></li>
                        <li><a href="/">Disclaimer</a></li>
                    </ul>
                </div>
                <div class="c-col-4">
                    &copy; 2024 Regiomed
                </div>

            </div>
        </div>
        
    </footer>
    
  </template>
  
  <script>
  export default {
    name: 'AppFooter',
    methods: {
        scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Enables smooth scrolling
        });
        }
    }
  }
  </script>
