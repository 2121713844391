import Breadcrumb from '@/components/AppBreadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      client_name: 'dd'
    };
  },
  created() {
    this.client_name = localStorage.getItem('username')
  },
  methods: {

  }
};