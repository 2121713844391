import axios from '../../../axios';
import Breadcrumb from '@/components/AppBreadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      form: {
        MODE: 'FREETEXT',  // default value
        CRIT: '',   // user input
        MANUF: '',
        state_active: 1,
        state_outofbusiness: null,
        state_BTM: null,
        state_fridge: null,
        state_SLlist: null,
        maxperpage: 100,
        SORT: 'ProdName ASC',
        sort_val:'ProdName',
        sort_order:'ASC',
      },
      searchInitiated: false,
      errorMessage: '',
      products: [],
      manufacturer: [],
      imageValid: true, 
      currentImage: '',
      fallbackImage: require('@/assets/images/product-placeholder.svg')
    };
  },
  created() {
    // this.searchProducts();
  },
  watch: {
    'form.MANUF'() {
      this.searchProducts();
    },
    'form.sort_val'(newSort) {
      if (newSort) {
        this.updateSort();
      }
    },
    'form.sort_order'(newOrder) {
      if (newOrder) {
        this.updateSort();
      }
    },
    'form.state_active'() {
      this.searchProducts();
    },
    'form.state_outofbusiness'() {
      this.searchProducts();
    },
    'form.state_BTM'() {
      this.searchProducts();
    },
    'form.state_fridge'() {
      this.searchProducts();
    },
    'form.state_SLlist'() {
      this.searchProducts();
    },

  },
  methods: {
    async searchProducts() {
      this.errorMessage = '';
      this.products = [];

      if (!this.form.CRIT || this.form.CRIT.trim() === '') {
        this.errorMessage = 'Bitte geben sie einen gültigen Suchbegriff ein.';
        this.searchInitiated = false; 
        return false;
      }

      try {

        this.errorMessage = 'loading....';

        const response = await axios.post('/Article/Search', this.form);       
        let responseData = response.data;

        // Add `showDetails` to each product after fetching
        if(responseData.Products){
            this.products = responseData.Products.map(product => {
                return { ...product, showDetails: false }; // Add showDetails property
            });
            this.searchInitiated = true;
            this.errorMessage = '';
        }else{
            this.products = [];
            this.errorMessage = 'Keine Produkte gefunden';
        }

        if(responseData.Hersteller){
            this.manufacturer = responseData.Hersteller;
        }else{
            this.manufacturer = [];
        }

      } 
      catch (error) {
        console.error("Error fetching products:", error);
        this.products = [];
        this.errorMessage = "Failed to fetch products. Please try again later.";
      }
    },
    updateSort() {
      this.form.SORT = `${this.form.sort_val} ${this.form.sort_order}`;
      this.form.FILTER
      this.searchProducts();
    },
    getProductImage(imageUrl) {
      return imageUrl || this.fallbackImage;
    },
    checkImageSize(index) {
      const img = this.$refs['productImage-' + index][0];  // Access the correct image ref
      if (img) {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Check if the image is smaller than 2px
        if (width < 2 || height < 2) {
          this.products[index].ImageFront = this.fallbackImage;
        }
      }
    }
  }
};