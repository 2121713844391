import axios from '../../../axios';
import Breadcrumb from '@/components/AppBreadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
        subClientID: null,
        subClient: null,
        persons: null,
        showSubClientDetails: false,
        showPersons: false,
        errorMessage: '',
    };
  },
  created() {
    this.subClientID = this.$route.params.subClientID;
    this.getSubClient();
  },
  methods: {
    async getSubClient() {
      this.errorMessage = '';
      this.subClient = [];
      try {

        this.errorMessage = "loading...";
        const responseSubClient = await axios.get(`/SubClient/Details/${this.subClientID}`);

        if(responseSubClient && responseSubClient.data){
            this.subClient = responseSubClient.data.SubClient;
            this.errorMessage = "";
        } else {
            this.errorMessage = "No orders found";
        }

        // Get Persons
        if(this.subClientID){
        const responseSubclient = await axios.get(`Person/ListForClientOnly/${this.subClientID}`);
        this.persons = responseSubclient.data;
        }else{
        this.persons = [];
        }

      } catch (error) {
        console.error("Error fetching orders:", error);
        this.subClient = [];
        this.errorMessage = "ERROR: Failed to fetch orders. Please try again later.";
      }
    },
  }
};