
import axios from '../../../axios';
import Breadcrumb from '@/components/AppBreadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      user: {},
      client: {},
      subClients: {},
      persons: {},
      documents: {},
      showUserDetails: null,
      showClientDetails: null,
      showSubClients: null,
      showPersons: null,
      showDocuments: null,
      error: ''
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {

      try {
        const userID = localStorage.getItem('userid');

        if (userID) {
          // Use the user ID in the API request
          const response = await axios.get(`/User/Details/${userID}`);
          
          // Set the user and client data from the response
          this.user = response.data.User || {};  
          this.client = response.data.Client || {};


          // Get sub clients
          if(this.client.key_Client){
            const responseSubclient = await axios.get(`SubClient/ListForClient/${this.client.key_Client}`);
            this.subClients = responseSubclient.data.SubClients;
          }else{
            this.subClients = [];
          }

          // Get Persons
          if(this.client.key_Client){
            const responseSubclient = await axios.get(`Person/ListForClientOnly/${userID}`);
            this.persons = responseSubclient.data;
          }else{
            this.persons = [];
          }

          // Get documents
          const responseDocuments = await axios.get(`Documents/ListForUser/`);
          if(responseDocuments.data){
            this.documents = responseDocuments.data.Documents;
          }else{
            this.documents = [];
          }


        } else {
          console.error('No user ID found in localStorage');
        }
      } catch (error) {
        this.error = 'Failed to fetch data: ' + error.message;
      }

    }
  }
};