import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import ShopPage from '@/components/ShopPage.vue';
import ArticleSearch from '@/components/ArticleSearch.vue';
import CockPit from '@/components/CockPit.vue';
import UserDetail from '@/components/UserDetail.vue';
import DocumentDetail from '@/components/DocumentDetail.vue';
import SubClientDetail from '@/components/SubClientDetail.vue';
import UserLogin from '@/components/UserLogin.vue';
import OrderList from '@/components/OrderList.vue';
import OrderDetail from '@/components/OrderDetail.vue';

Vue.use(Router);

const routes = [
  { path: '/home', component: HomePage, meta: { breadcrumb: 'Home' } },
  { path: '/shop', component: ShopPage, meta: { breadcrumb: 'Shop',  } },

  { path: '/shop/products', component: ArticleSearch, meta: { breadcrumb: 'Produktsuche', parent: '/shop',  parentText: 'Shop'  } },

  { path: '/cockpit', component: CockPit, meta: { breadcrumb: 'Cockpit',  } },
  { path: '/user', component: UserDetail, meta: { breadcrumb: 'Benutzer',  } },
  { path: '/user/subclient/:subClientID', name: 'SubClientDetail', component: SubClientDetail, meta: { breadcrumb: 'SubClient', parent: '/user',  parentText: 'Benutzer' } },

  { path: '/user/document/:documentID', name: 'DocumentDetail', component: DocumentDetail, meta: { breadcrumb: 'DocumentDetail', parent: '/user',  parentText: 'Benutzer' } },


  { path: '/login', component: UserLogin, meta: { breadcrumb: 'Login',  } },
  { path: '/orders', component: OrderList, meta: { breadcrumb: 'Bestellübersicht', } },
  { path: '/orders/detail/:orderId', name: 'OrderDetail', component: OrderDetail, meta: { breadcrumb: 'Bestellung', parent: '/orders',  parentText: 'Bestellübersicht' } },
  { path: '*', redirect: '/home' } // Redirect to login if no route matches
];


const router = new Router({
  mode: 'history',
  routes
});

// Add a global navigation guard
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  // if (token && to.path === '/login') {
  //   return next('/user'); 
  // }

  if (to.path === '/login') {
    return next();
  }
  if (!token) {
    return next('/login');
  }

  next();
});


export default router;
