export function formatDate(dateString) {
    const date = new Date(dateString);
  
    // Extract day, month, year, hours, and minutes
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    // Construct the formatted date string
    return `${day}.${month}.${year}`;
}
export function formatDateTime(dateString) {
    const date = new Date(dateString);
  
    // Extract day, month, year, hours, and minutes
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    // Construct the formatted date string
    return `${day}.${month}.${year} ${hours}:${minutes}`;
}