
import axios from '../../../axios';
import Breadcrumb from '@/components/AppBreadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      user: {},   // To store User data
      client: {}, // To store Client data
      orders: {}, // To store Client data
      error: '',   // To store any error messages
      displayName: '',
      showDetails: false,      
      form: {
        MODE:'CLIENT_ALL',
        KEY:10576,
        page:1,
        maxperpage:10,
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {

      try {
        const userID = localStorage.getItem('userid');

        if (userID) {
          // Use the user ID in the API request
          const responseUser = await axios.get(`/User/Details/${userID}`);
          
          // Set the user and client data from the response
          this.user = responseUser.data.User || {};  
          this.client = responseUser.data.Client || {};


          // Get Orders
          const responseOrder = await axios.post('/Order/List', this.form);
          if(responseOrder && responseOrder.data.Orders){
            this.orders = responseOrder.data.Orders;
          } else {
            console.error('No orders found in localStorage');
          }

        } else {
          console.error('No user ID found in localStorage');
        }
      } catch (error) {
        this.error = 'Failed to fetch data: ' + error.message;
      }

    }
  }
};