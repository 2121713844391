import axios from 'axios';
import router from './router';

const instance = axios.create({
  baseURL: 'https://www.dispomanager.ch/TestService/'
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      // console.log(token)
      const authHeader = token.startsWith('Bearer ') ? token : `Bearer ${token}`;

      config.headers.Authorization = authHeader;
    }else{
      router.push('/login');
      return Promise.reject(new Error('No token found, redirecting to login.'));      
    }
    return config;
  },  
  response => {
    // Return the response if successful
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response Interceptor
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Handle response errors
    console.log(error.response.data.ERROR)
    if (error.response && (error.response.data.ERROR === 'TOKEN_EXPIRED_OR_INVALID' || error.response.ERROR === 'USER_TOKEN')) {
      console.log('Token expired or invalid.');
      
      localStorage.removeItem('token');
      localStorage.removeItem('userid');

      // Redirect to login page
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

export default instance;
