<template>
    <header class="c-header" role="banner">
        <!-- header meta -->
        <div class="c-container-wide c-header-meta">
            <div class="c-container c-container-no-padding">
                <span class="c-meta-mail"><a class="c-icon c-link-email" href="mailto:info@dispomanager.ch">E-Mail an Regiomed</a></span>
                <ul class="c-meta-list">
                    <li><router-link to="/cockpit" class="c-icon c-link-user" href="/cockpit">{{ displayName }}</router-link></li>
                    <li v-if="isLoggedIn">
                        <a href="#" @click.prevent="logout">Abmelden</a>
                    </li>
                    <li v-else>
                        <router-link to="/login">Anmelden</router-link>
                    </li>
                </ul>
            </div>
        </div>
        
        <div class="c-container c-container-no-padding c-header-inner">
            <div class="c-header-logo">
                <div class="c-header-logo-1">
                    <router-link to="/home">
                        <img src="../assets/images/logo-dispomanager.jpg" alt="" />
                    </router-link>
                </div>
                <div class="c-header-logo-2">
                    <router-link to="/home">
                        <img src="../assets/images/logo-regiomed.svg" alt="" />
                    </router-link>
                </div>
            </div>
            <div class="c-header-nav">
                <ul class="c-header-nav-icons-list">
                    <li>
                        <router-link to="/orders" class="c-link-meta-recipe">
                            <span class="c-icon c-link-meta-recipe-icon"><span class="c-hide-visually">offene Rezepte</span></span>
                            <span class="c-link-meta-basket-items">?</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/orders" class="c-link-meta-basket">
                            <span class="c-icon c-link-meta-basket-icon"><span class="c-hide-visually">offene Warenkörbe</span></span>
                            <span class="c-link-meta-basket-items">?</span>
                        </router-link>
                    </li>                    
                </ul>
                <nav class="c-main-nav">
                    <ul class="c-main-nav-list">
                        <li><router-link to="/shop/products">Shop</router-link></li>
                        <li><router-link to="/orders">Bestellübersicht</router-link></li>
                        <li><router-link to="/user">Benutzer</router-link></li>
                        <!-- <li><router-link to="/login">Login</router-link></li> -->
                    </ul>
                </nav>
            </div>          
        </div>
    </header>
</template>

<script>
    export default {
        name: 'AppHeader',
        data() {
            return {
                defaultName: 'Guest',
                displayName: '',
                isLoggedIn: false
            };
        },
        created() {
            this.setDisplayName();
            this.$root.$on('login-success', this.setDisplayName);

            // Check if user is logged in
            const token = localStorage.getItem('token');
            this.isLoggedIn = !!token;

        },
        methods: {
            setDisplayName() {
                // Fetch the username from localStorage
                const storedName = localStorage.getItem('username');
                this.isLoggedIn = !!storedName;

                // If a value is found, use it. Otherwise, use the default name.
                this.displayName = storedName ? storedName : this.defaultName;
            },    
            logout() {
                // Clear any user-related data from localStorage
                localStorage.removeItem('token');
                localStorage.removeItem('userid');
                localStorage.removeItem('username');

                this.isLoggedIn = false;
                this.setDisplayName();

                // Redirect to the login page
                this.$router.push('/login');
            }
        }
    }
</script>
