import axios from 'axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: 'saentis25019@gmail.com',
      // password: 'dispom',
      password: '',
      userid: false,
      isLoggedIn: null,
      errorMessage: '',
    };
  },
  created() {
    const userID = localStorage.getItem('userid');
    if (userID) {
      this.isLoggedIn = true;
    }
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('https://www.dispomanager.ch/TestService/Token/Create', {
          Email: this.username,
          B64Password: btoa(this.password) 
        });

        let responseData = response.data;

        const token = responseData.data.Token;
        const UserID = responseData.data.UserID;
        const UserEmail = responseData.data.Email;
        const UserName = responseData.data.NameFL;
        
        localStorage.setItem('token', token);
        localStorage.setItem('userid', UserID	);

        // Use the user ID in the API request
        localStorage.setItem('useremail', UserEmail	);
        localStorage.setItem('username', UserName	);
        this.$root.$emit('login-success');
        this.$router.push('/home');

      } catch (error) {
        this.errorMessage = 'Invalid username or password';
      }
    }
  }
};