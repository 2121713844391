export const breadcrumbMixin = {
    computed: {
        computedBreadcrumbs() {
            let matchedRoutes = this.$route.matched;
            let breadcrumbs = matchedRoutes.map(route => ({
            text: route.meta.breadcrumb || route.path,
            path: route.path,
            isActive: route === matchedRoutes[matchedRoutes.length - 1]  // Mark last breadcrumb as active
            }));
            
            // Check for parent breadcrumb and manually add it if it exists
            const lastRoute = matchedRoutes[matchedRoutes.length - 1];
            if (lastRoute && lastRoute.meta.parent) {
            breadcrumbs.unshift({
                text: lastRoute.meta.parentText,  // Define the parent breadcrumb text here
                path: lastRoute.meta.parent
            });
            }
    
            return breadcrumbs;
        }
    }
  };
  